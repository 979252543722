import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default function Register() {
  const [username, setUsername] = useState("");
  const [externalId, setExternalId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      // Handle password mismatch
      toast.error("Passwords do not match.");
      return;
    }
    const cleanUsername = username.replace(/\D/g, "");
    // await dispatch(registerUser({
    //   username: cleanUsername,
    //   password
    // }))
    navigate("/");
  };

  return (
    <div className="min-h-[calc(100vh-64px)] flex items-center justify-center bg-gray-50 px-4">
      <ToastContainer />
      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col gap-4 bg-white shadow-xl rounded-lg px-8 py-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium  mb-1"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Format: 0913535139
              </p>
            </div>

            <div>
              <label
                htmlFor="externalid"
                className="block text-sm font-medium  mb-1"
              >
                User Account Number (External ID)
              </label>
              <input
                id="externalid"
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                value={externalId}
                onChange={(e) => setExternalId(e.target.value)}
                required
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Format: 0913535139
              </p>
            </div>

            <div>
              <label
                htmlFor="phonenumber"
                className="block text-sm font-medium  mb-1"
              >
                phonenumber
              </label>
              <input
                id="phonenumber"
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
              />
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                Format: 0913535139
              </p>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium  mb-1"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                placeholder="Create a password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div>
              <label
                htmlFor="confirm-password"
                className="block text-sm font-medium  mb-1"
              >
                Confirm Password
              </label>
              <input
                id="confirm-password"
                type="password"
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md "
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Register
                </button>
              </div>

            <div className="flex items-center">
              <input
                id="terms"
                type="checkbox"
                required
                className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="terms" className="ml-2 block text-sm ">
                I agree to the{" "}
                <Link
                  to="/terms"
                  className="text-primary hover:text-primary-hover"
                >
                  Terms and Conditions
                </Link>
              </label>
            </div>

            <div className="text-center text-sm text-gray-600 dark:text-gray-400">
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-primary hover:text-primary-hover"
              >
                Sign in here
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
