import React from "react";
import CommingSoon from "./comingsoon.gif";

function Fundtransfer() {
  return (
    <div className="min-h-[calc(100vh-100px)] flex flex-col items-center justify-center  ">
      <img src={CommingSoon} height="auto" width="250vh" rounded alt="Coming Soon" />
      <div class="p-20 font-bold text-2xl justify-center">
        ከ Compulsary Saving ብድርዎት የሚከፍሉበትን አሰራር እያመቻቸን ስለሆነ ፣ በቅርብ ግዜ ተግባራዊ የሚደረግ ይህንን አገልግሎት ይጠብቁ፡፡
      </div>
    </div>
  );
}

export default Fundtransfer;
