import React from "react";

function DateInputTw({ value, onChange, placeholder }) {
  return (
    <label className="w-full py-1">
      <div className="label">
        <span className="label-text">{placeholder} </span>
      </div>
      <input
        type="date"
        onChange={onChange}
        placeholder={placeholder}
        className="input input-bordered w-full form-control"
        required
      />
    </label>
  );
}

export default DateInputTw;
