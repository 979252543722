import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function LoanApplication() {
  const { data } = useSelector((store) => store.customer);
  const [activeLoanPlan, setActiveLoanPlan] = useState({});

  useEffect(() => {
    getAllSavings();
  }, []);

  const getAllSavings = async () => {
    try {
      const res_as = await axios.get(
        `https://back.amigoslps.com/fineract/allsaving?entityAccountNo=${data.clients[0]}`
      );

      setActiveLoanPlan(
        res_as.data.loanAccounts !== undefined &&
          (res_as.data.loanAccounts?.find((ln) => ln.status.id !== 500) ===
          undefined
            ? new Object()
            : res_as.data.loanAccounts?.find((ln) => ln.status.id !== 500))
      );
    } catch (e) {}
  };

  return (
    <div>
      <div>Active Loan Plan = {Object.keys(activeLoanPlan).length}</div>
      <div> {JSON.stringify(activeLoanPlan)} </div>
      <div> {activeLoanPlan.id} </div>
    </div>
  );
}

export default LoanApplication;
