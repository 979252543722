import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function ProfileNew() {
  const [clientreport, setClientReport] = useState([]);
  const { externalId } = useParams();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      const userreport = await axios.get(
        `https://back.amigoslps.com/fineract/clientreport?entityExternalId=${externalId}`
      );

      setClientReport(userreport?.data?.data[0]?.row);
    } catch (error) {
    } finally {
    }
  };

  return <div>ProfileNew</div>;
}

export default ProfileNew;
