import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  clients: "",
};

const authSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    custRegister: (state, action) => {
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload.responseData.user,
      };
    },

    custLogin: (state, action) => {
      console.log(action);
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload.responseData,
      };
    },

    custLogout: (state, action) => {
      return { data: {}, isAuthenticated: false };
    },
  },
});

export const { custRegister, custLogin, custLogout } = authSlice.actions;

export default authSlice.reducer;
