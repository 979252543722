import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import store from "./Common/redux/Store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import NotFound from "./Common/NotFound";
import ProtectedRoute from "./Common/ProtectedRoute";
import Profile from "./Common/Profile";
import Savings from "./Savings";

// Officer
import AboutUs from "./AboutUs";
import ChangePass from "./Common/ChangePass";
import LoanApplication from "./LoanApplication";

import Loan300detail from "./Loan300detail";

import LoanRequestForm from "./LoanRequestForm";
import Faqs from "./Faqs";
import Services from "./Services";
import ForgetPassword from "./ForgetPassword";
import Register from "./Register";
import ProfileNew from "./Common/ProfileNew";
import Fundtransfer from "./Fundtransfer";
import LoanLpsStatus from "./LoanLpsStatus";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <Savings />
                </ProtectedRoute>
              }
            />

            <Route path="/savings" element={<Savings />} />

            <Route path="*" element={<NotFound />} />
            <Route path="login" element={<Login />} />
            <Route path="services" element={<Services />} />
            <Route path="register" element={<Register />} />
            <Route path="forgotpassword" element={<ForgetPassword />} />

            <Route path="aboutus" element={<AboutUs />} />
            <Route path="/profile" element={<Profile />} />
            {/* <Route path="/profiles/:externalId" element={<ProfileNew />} /> */}
            <Route path="/changepass" element={<ChangePass />} />
            <Route path="/loanapplication" element={<LoanApplication />} />
            <Route path="/fundtransfer" element={<Fundtransfer />} />

            <Route path="/loan300detail/:loanId" element={<Loan300detail />} />
            <Route path="/loanlpsstatus/:customerId/:loanId" element={<LoanLpsStatus />} />
            <Route path="/loanrequest" element={<LoanRequestForm />} />
            <Route path="/faqs" element={<Faqs />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
