import React from "react";

function KeyValue({ thekey, value, isodd }) {
  return (
    <div className={`p-2 m-1 flex justify-between rounded-sm ${isodd === "True" ? "bg-white" : "bg-orange-100"}`} >
      <div>{thekey}</div>
      <div>{value}</div>
    </div>
  );
}

export default KeyValue;
