import React from "react";

import Fitsum from "./staff/fitsum.jpg";
import Zola from "./staff/zeloa.jpg";
import Hilina from "./staff/hilina.jpg";
import Hana from "./staff/hanacrop.jpg";

const AboutUs = () => {
  return (
    <div className="bg-gray-100 py-16">
      {/* Page Title */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800">About Us</h1>
       <div className="m-10">
       <p className="text-lg text-gray-600 mt-4">
          10 years from now in 2005 E.C. 20 friends (ie. ‘Amigos’) planned and
          founded Amigos saving and credit cooperation with a capital of only
          9,000ETB. Since then, our company has lived to fulfill its purpose of
          making its members successful by providing the most suitable and
          flexible financial service products. Amigos is well known to consider
          and treat its member customers and staff as family. Right now Amigos
          sacco has more that Six thousand members, nearly 80 employees and a
          total asset of one billion five hundred million birr.
        </p>
       </div>
      </div>

      {/* Vision, Mission, Values Section */}
      <div className="max-w-7xl mx-auto px-4">
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-10">
            Vision, Mission & Values
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 shadow-lg rounded-lg">
              <h3 className="text-xl font-semibold text-gray-700 mb-4">
                Our Vision
              </h3>
              <p className="text-gray-600">
                To be a world class financial and non-financial service provider
                by the year 2030.
              </p>
            </div>

            <div className="bg-white p-8 shadow-lg rounded-lg">
              <h3 className="text-xl font-semibold text-gray-700 mb-4">
                Our Mission
              </h3>
              <p className="text-gray-600">
                {" "}
                We aspire to enhance the living standards of our members in
                Ethiopia, by providing financial and non-financial services in
                short period using up-to date technology with
                qualified,energetic,passionate and committed staff inorder to
                maximize value to our stakeholders in a socially responsible
                manner.
              </p>
            </div>

            <div className="bg-white p-8 shadow-lg rounded-lg">
              <h3 className="text-xl font-semibold text-gray-700 mb-4">
                Our Values
              </h3>
              <p className="text-gray-600">
                Our values act as a roadmap for our daily tasks. They describe
                how we should serve our customers and work together as
                colleagues.
                <ul>
                  <li> Our core values are: </li>
                  <li> 1. Team Work </li>
                  <li> 2. Be respectful </li>
                  <li> 3. Keep promises </li>
                  <li> 4. Make it easy </li>
                  <li> 5. Be inspiring </li>
                </ul>
              </p>
            </div>
          </div>
        </section>

        {/* Our Management Team Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-10">
            Our Management Team
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Management Team Member 1 */}
            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <img
                src={Fitsum}
                alt="Fitsum"
                className="w-32 h-32 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-700">
                Fitsum Abrha
              </h3>
              <p className="text-gray-500">CEO</p>
              <p className="text-gray-600 mt-4">
                Fitsum Abrha is a visionary leader who has transformed Amigos
                Savings and Credit Cooperative into a modern, customer-centric
                financial cooperative. His commitment to empowering members and
                promoting financial literacy has helped to make Amigos SACCO a
                trusted partner for thousands of individuals and businesses.
              </p>
            </div>
            {/* Management Team Member 2 */}

            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <img
                src={Zola}
                alt="Team Member 2"
                className="w-32 h-32 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-700">
                Zelalem Yilma
              </h3>
              <p className="text-gray-500">
                Deputy Manager and Business Development Head
              </p>
              <p className="text-gray-600 mt-4">
                A graduate from Adama science and technology university with the
                field of Civil Engineering. An Experienced Business Development
                manager with a demonstrated history of working in the marketing
                industry. MBA graduate from CPU Business and Information college
                with a great skill of Business, leadership, Negotiation,
                Strategic Planning, work with team, Communication,
                Solution-oriented, and Meeting deadlines.
              </p>
            </div>

            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <img
                src={Hilina}
                alt="Team Member 2"
                className="w-32 h-32 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-700">
                Hilina Getachew
              </h3>
              <p className="text-gray-500">Finance Head</p>
              <p className="text-gray-600 mt-4"></p>
            </div>

            <div className="bg-white p-6 shadow-lg rounded-lg text-center">
              <img
                src={Hana}
                alt="Team Member 2"
                className="w-32 h-32 rounded-full mx-auto mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-700">
                Hana Tesfaye
              </h3>
              <p className="text-gray-500">Member Service Department Head</p>
              <p className="text-gray-500">Member Service Department Head</p>
              <p className="text-gray-600 mt-4">
                A driven and ambitious young woman holding a BSc degree in Civil
                engineering from the well-known Mekelle University and Currently
                pursuing her Master's degree in Business Administration at St
                Mary University. Head of Member service and stakeholders
                relation department, having excellent communication, dedication
                and leader qualities.
              </p>
            </div>

            {/* Additional team members */}
            {/* Repeat similar blocks for more team members */}
          </div>
        </section>

        {/* Additional Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-10">Our Story</h2>
          <div className="bg-white p-8 shadow-lg rounded-lg">
            <p className="text-gray-600">
              10 years from now in 2005 E.C. 20 friends (ie. ‘Amigos’) planned
              and founded Amigos saving and credit cooperation with a capital of
              only 9,000ETB. Since then, our company has lived to fulfill its
              purpose of making its members successful by providing the most
              suitable and flexible financial service products. Amigos is well
              known to consider and treat its member customers and staff as
              family. Right now Amigos sacco has more that Six thousand members,
              nearly 80 employees and a total asset of one billion five hundred
              million birr.
            </p>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="text-center py-16 bg-orange-400 text-white">
          <h2 className="text-3xl font-bold mb-4">Join Our Journey</h2>
          <p className="mb-6 text-lg">
            Interested in working with us or learning more? We’d love to hear
            from you!
          </p>
          <button className="bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600">
            Contact Us
          </button>
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
