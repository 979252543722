function datesLeftForNextMonthPay(repayPeriods) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    for (const period of repayPeriods) {
        if (!period.complete) {
            const dueDate = new Date(period.dueDate[0], period.dueDate[1] - 1, period.dueDate[2]);

            // Check if the due date is in the next month
            const isNextMonth =
                (dueDate.getFullYear() === currentYear && dueDate.getMonth() === currentMonth + 1) ||
                (dueDate.getFullYear() === currentYear + 1 && dueDate.getMonth() === 0 && currentMonth === 11);

            if (isNextMonth) {
                const timeDifference = dueDate - currentDate;
                return Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert ms to days
            }
        }
    }
    return 0; // Return 0 if no incomplete period is found in the next month
}

function currentMonthPaymentMadeOrNot(repayPeriods) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  for (const period of repayPeriods) {
    const [year, month] = period.dueDate;

    // Check if the dueDate month and year match the current month and year
    if (year === currentYear && month === currentMonth) {
      return period.complete;
    }
  }
  return false;
}

module.exports = { datesLeftForNextMonthPay, currentMonthPaymentMadeOrNot };
