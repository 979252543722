import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, ListGroup, Modal } from "react-bootstrap";
import { FaBuilding, FaEye, FaUser, FaUserAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";

function LoanLpsStatus() {
  const [lpsLoanPlan, setlpsLoanPlan] = useState({});
  const [lpsCustomer, setlpsCustomer] = useState({});
  const { loanId, customerId } = useParams();
  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState([]);

  useEffect(() => {
    getCustomer_Loan();
  }, []);

  const getCustomer_Loan = async () => {
    try {
      const ln_res = await axios.get(`https://back.amigoslps.com/loan/loansbyamisisloanid/${loanId}`);
      setlpsLoanPlan(ln_res.data);
      const cus_res = await axios.get(`https://back.amigoslps.com/customer/customers/${customerId}`);
      console.log("cus_res.data");
      console.log(cus_res.data);
      setlpsCustomer(cus_res.data);
    } catch (error) {
      console.error("Error in redirecting:", error);
    }
  };

  return (
    <div>
      {/* Modal Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={`https://back.amigoslps.com${viewObj?.fileUrl}`} style={{ width: "100%", height: "100%" }} />
        </Modal.Body>
      </Modal>
      {/* Modal End  */}

      <ListGroup>
        <ListGroup.Item>{`ሙሉስም : ${lpsLoanPlan?.customer?.amDisplayName}`}</ListGroup.Item>
        <ListGroup.Item>{`አድራሻ : ${lpsLoanPlan?.customer?.regionId?.amDescription} - ${lpsLoanPlan?.customer?.zoneId?.amDescription} - ${lpsLoanPlan?.customer?.woredaId?.amDescription}`}</ListGroup.Item>
        <ListGroup.Item>{`ስልክ : ${lpsLoanPlan?.customer?.mobileNo}`}</ListGroup.Item>
      </ListGroup>

      <ListGroup className="mt-2">
        <ListGroup.Item>{`የብድር መጠን : ${lpsLoanPlan?.approvedPrincipalDisbursed?.toLocaleString()}`}</ListGroup.Item>
      </ListGroup>

      <div className="mt-2">
        {lpsCustomer?.isMarried ? (
          <Card>
            <Card.Header>ያገባ ተበዳሪ የሚያሟላቸው መረጃዎች </Card.Header>
            <Card.Body>{lpsCustomer?.marriedgeneralfiles?.length > 0 ? <></> : <></>}</Card.Body>
          </Card>
        ) : (
          <Card>
            <Card.Header>ያላገባ ተበዳሪ የሚያሟላቸው መረጃዎች</Card.Header>
            <Card.Body>
              {lpsCustomer?.singlegeneralfiles?.length > 0 ? (
                <ListGroup className="mt-2">
                  {lpsCustomer?.singlegeneralfiles.map((file) => {
                    return (
                      <ListGroup.Item className={file.isUploaded ? " bg-green-500 " : "bg-red-300"}>
                        {file.amDesc}{" "}
                       
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        )}
      </div>

      {/* car collateral  */}

      <div className="mt-2">
        {lpsLoanPlan?.collateralcar?.length > 0 ? (
          <Card>
            <Card.Header>የመኪና ዋስትና አስይዘዋል </Card.Header>
            <Card.Body>
              <ListGroup className="mt-2">
                {lpsLoanPlan?.collateralcar?.map((car) => (
                  <>
                    <ul className="mb-3">
                      <li className="flex gap-2">Model : {car.model?.enName}</li>
                      <li className="flex gap-2">Plate : {car.carPlate}</li>
                    </ul>
                    {car.carfiles.map((file) => (
                      <ListGroup.Item className={file.isUploaded ? " bg-green-500 " : "bg-red-300"}>
                        {file.amDesc}{" "}
                      </ListGroup.Item>
                    ))}
                  </>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        ) : (
          <></>
        )}
      </div>

      {/* home collateral  */}

      <div className="mt-2">
        {lpsLoanPlan?.collateralhome?.length > 0 ? (
          <Card>
            <Card.Header>የቤት ዋስትና አስይዘዋል </Card.Header>
            <Card.Body>
              <ListGroup className="mt-2">
                {lpsLoanPlan?.collateralhome?.map((home) => (
                  <>
                    <ul className="mb-3">
                      <li className="flex gap-2">የቤት አይነት ፡ {home.hometype?.amName}</li>
                      <li className="flex gap-2">የቤቱ ልዩ ቦታ፡ {home.locationtxt + " - " + home.amName} </li>
                      <li className="flex gap-2">ስፋት በካሬ ፡ {home.homearea}</li>
                    </ul>
                    {home.homefiles.map((file) => (
                      <ListGroup.Item className={file.isUploaded ? " bg-green-500 " : "bg-red-300"}>
                        {file.amDesc}{" "}
                      </ListGroup.Item>
                    ))}
                  </>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        ) : (
          <></>
        )}
      </div>

      {/* salary collateral  */}
      <div className="mt-2">
        {lpsLoanPlan?.collateralemployee?.length > 0 ? (
          <Card>
            <Card.Header>የደመወዝ ዋስትና አስይዘዋል </Card.Header>
            <Card.Body>
              <ListGroup className="mt-2">
                {lpsLoanPlan?.collateralemployee?.map((salary) => (
                  <>
                    <ul className="mb-3">
                      <li className="flex gap-2">
                        <FaUserAlt /> {salary.empAmName}
                      </li>
                      <li className="flex gap-2">
                        <FaBuilding /> {salary.companyname}
                      </li>
                    </ul>
                    {salary.salaryfiles.map((file) => (
                      <ListGroup.Item className={file.isUploaded ? " bg-green-500 " : "bg-red-300"}>
                        {file.amDesc}{" "}
                      </ListGroup.Item>
                    ))}
                  </>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default LoanLpsStatus;
