import React from "react";
import { Accordion } from "react-bootstrap";

function Services() {
  return (
    <div>
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>የአባልነት የብቃት መስፈርቶች </Accordion.Header>
          <Accordion.Body>
            የአሚጎስ SACCO አባል ለመሆን የሚከተሉትን መስፈርቶች ማሟላት አለቦት፡፡
            <ol>
              <li> አባልነትዎን ለማክበር በትንሹ ወደ ሂሳብዎ ተቀማጭ ለማድረግ ፈቃደኛ ይሁኑ፡፡ </li>
              <li> በአሚጎስ SACCO ደንቦች የተቀመጡትን አነስተኛ መስፈርቶች ማሟላት፡፡ </li>
              <li> ቋሚ የገቢ ምንጭ ይኑርዎት፡፡ </li>
              <li> ጥሩ የብድር ታሪክ ይኑርዎት፡፡ </li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>

        {/* የአባልነት ጥቅሞች */}
        <Accordion.Item eventKey="2">
          <Accordion.Header>የአባልነት ጥቅሞች </Accordion.Header>
          <Accordion.Body>
            የSACCO አባል እንደመሆንዎ መጠን የሚከተሉትን ጥቅሞች ማግኘት ይችላሉ፡፡
            <ol>
              <li>
                1. የቁጠባ ሂሳቦችን ፣ ብድሮችን እና ኢንሹራንሶችን ጨምሮ የተለያዩ የፋይናንስ ምርቶችን እና
                አገልግሎቶችን ማግኘት፡፡{" "}
              </li>
              <li>
                2. በተርፍ መጋራት ውስጥ መሳተፍ ፣ይህ ማለት በአሚጎስ SACCO የፋይናንስ ደንብ ላይ በመመስረት
                የትርፍ ክፍፍል መቀበል ይቻላል፡፡{" "}
              </li>
              <li>3. በቁጠባ እና በብድር ላይ ተወዳዳሪ የወለድ ተመኖች፡፡ </li>
              <li>
                4. አሚጎስ የሁሉም ሰው ያፋይናንስ ፍላጎት ልዩ እንደሆነ ተረድቷል ፣ ለዚህም ነው አሚጎስ ለፍላጎትዎ
                ተስማሚ የመክፈያ መርሃ ግብር የሚያቀርበው፡፡{" "}
              </li>
              <li>5. ከሰራተኞቻችን ድጋፍ፡፡ </li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>
        {/* የአባልነት ጥቅሞች */}

        {/* አባል ይሁኑ  */}
        <Accordion.Item eventKey="3">
          <Accordion.Header>አባል ይሁኑ </Accordion.Header>
          <Accordion.Body>
            አሚጎስን ለመቀላቀል በቀላሉ የሚከተሉትን ደረጃዎች ይከተሉ፡፡
            <ol>
              <li>
                1. የአባልነት ማመልከቻውን እዚህ ይሙሉ ወይም የአባልነት መጽሓፍ ለማግኘት ቢሮአችንን ይጎብኙ፡፡{" "}
              </li>
              <li>2. ማንኛውም ተጨማሪ መረጃ ያቅርቡ፡፡ </li>
              <li>3. ማመልከቻዎ በእኛ ባለሙያ አባልነት ኮሚቴ ይገመገማል፡፡ </li>
              <li>
                4. አንዴ ማመልከቻዎ ተቀባይነት ካገኘ ፣ የAmigos SACCO ሙሉ አባል ይሆናሉ ፣ የአባልነት
                ሰርቲፊኬት ይቀበላሉ እና ሁሉንም የአሚጎስ አገልግሎቶችን ፣ ምርቶችን እና ጥቅሞችን ያገኛሉ፡፡{" "}
              </li>
            </ol>
          </Accordion.Body>
        </Accordion.Item>
        {/* አባል ይሁኑ  */}

        {/* ክፍያዎች  */}
        <Accordion.Item eventKey="4">
          <Accordion.Header>ክፍያዎች </Accordion.Header>
          <Accordion.Body>
            የአሚጎስ SACCO አባል ለመሆን ፣ ለመቆጠብም ሆነ ብድር ለመውሰድ ቢያስቡ ለእያንዳንዱ ተጠቃሚ የአባልነት ክፍያ 1000
            ብር ይሆናል፡፡ አባላት የፈለጉትን ባንክ ተጠቅመው ክፍያዎችን ለመፈጸም የሚያስችል ምቹነት አላቸው፡፡ ክፍያ ከተፈጸመ በኋላ ገለሰቦች
            ለክፍያ ማረጋገጫ የሚሆን ደረሰኝ ፎቶ እንዲያቀርቡ ይጠበቅባቸዋል፡፡ ክፍያው ከተረጋገጠ በኋላ በድርጅቱ የሚሰጡትን ልዩ ልዩ ጥቅማጥቅሞች
            እና አገልግሎቶችን በማግኘት የአሚጎስ SACCO አባል በመሆን በይፋ ይመዘገባሉ፡፡ 

          </Accordion.Body>
        </Accordion.Item>
        {/* ክፍያዎች  */}
      </Accordion>
    </div>
  );
}

export default Services;
