import axios from "axios";
import React, { useEffect, useState } from "react";
import { Alert, Card, ListGroup } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { PulseLoader } from "react-spinners";

function Savings() {
  const navigate = useNavigate();
  const { data, isAuthenticated } = useSelector((store) => store.customer);
  const [allLoanPlans, setallLoanPlans] = useState([]);

  const [loanSaving, setLoanSaving] = useState([]);
  const [voluntary, setVoluntary] = useState([]);
  const [funeral, setFuneral] = useState([]);
  const [compulsary, setCompulsary] = useState([]);
  const [timeDeposit, setTimeDeposit] = useState([]);

  const [loanSavingSum, setLoanSavingSum] = useState(0);
  const [voluntarySum, setVoluntarySum] = useState(0);
  const [funeralSum, setFuneralSum] = useState(0);
  const [compulsarySum, setCompulsarySum] = useState(0);
  const [timeDepositSum, setTimeDepositSum] = useState(0);

  const [savingAccountTotal, setSavingAccountTotal] = useState(0);
  const [shareAccountTotal, setShareAccountTotal] = useState(0);

  const [activeLoanPlans, setactiveLoanPlans] = useState([]);
  const [lpsLoanPlan, setlpsLoanPlan] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sum_lnsaving = loanSaving?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setLoanSavingSum(sum_lnsaving);

    const sum_voluntary = voluntary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setVoluntarySum(sum_voluntary);

    const sum_funeral = funeral?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setFuneralSum(sum_funeral);

    const sum_compulsary = compulsary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setCompulsarySum(sum_compulsary);

    const sum_timed = timeDeposit?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setTimeDepositSum(sum_timed);
  }, [loanSaving, voluntary, funeral, compulsary, timeDeposit]);

  useEffect(() => {
    getAllSavings();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      return navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const getAllSavings = async () => {
    setIsLoading(true);
    try {
      const res_as = await axios.get(
        `https://back.amigoslps.com/fineract/allsaving?entityAccountNo=${data.clients[0]}`
      );

      let loanPlanId = res_as.data?.loanAccounts.filter((x) => x.status.id === 100)[0].id;
      const actp = await axios.get(`https://back.amigoslps.com/loan/loansbyamisisloanid/${loanPlanId}`);
      setlpsLoanPlan(actp.data);
      console.log("lps loan plan");
      console.log(actp.data);
      console.log("customerid");
      console.log(actp.data?.customer.id);

      console.log(res_as.data);
      setallLoanPlans(res_as.data?.loanAccounts ? res_as.data?.loanAccounts.filter((x) => x.status.id !== 100) : []);
      setactiveLoanPlans(res_as.data?.loanAccounts.filter((x) => x.status.id === 100));

      const sum_savings = res_as.data.savingsAccounts?.reduce((accum, obj) => {
        return accum + (obj.accountBalance ? obj.accountBalance : 0);
      }, 0);

      setLoanSaving(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("LOAN")));
      setVoluntary(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("VOLUN")));
      setFuneral(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("FUN")));
      setCompulsary(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("COMP")));
      setTimeDeposit(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("TIME")));

      const sum_shares = res_as.data.shareAccounts?.reduce((accum, obj) => {
        return accum + obj.totalApprovedShares;
      }, 0);

      setShareAccountTotal(sum_shares);
      setSavingAccountTotal(sum_savings);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: "#FFA500" }}>Loading ...</div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Alert>ቁጠባዎች</Alert>
      <Card className="mt-1">
        <Card.Header className="flex flex-row items-center gap-2">
          <FaUserAlt /> የተበዳሪ መረጃ
        </Card.Header>
        <ListGroup>
          <ListGroup.Item>{`Voluntary : ${voluntarySum?.toLocaleString()}`}</ListGroup.Item>
          <ListGroup.Item>{`Loan Saving : ${loanSavingSum?.toLocaleString()}`}</ListGroup.Item>
          <ListGroup.Item>{`Funeral : ${funeralSum?.toLocaleString()}`}</ListGroup.Item>
          <ListGroup.Item className="flex justify-between">
            {`Compulsary : ${compulsarySum?.toLocaleString()}`}
            <Link to={`/fundtransfer`} className="font-medium text-orange-400 underline hover:underline text-xl">
              ከዚህ ይክፈሉ
            </Link>
          </ListGroup.Item>
          <ListGroup.Item>{`Time deposit : ${timeDepositSum?.toLocaleString()}`}</ListGroup.Item>
          <ListGroup.Item>{`Saving Accounts : ${savingAccountTotal?.toLocaleString()}`}</ListGroup.Item>
          <ListGroup.Item>{` Share Accounts : ${shareAccountTotal?.toLocaleString()}`}</ListGroup.Item>
        </ListGroup>
      </Card>

      <Alert className="mt-2">ብድሮች</Alert>

      <div className="flex flex-col sm:flex-row w-full gap-2">
        {allLoanPlans.length > 0 ? (
          allLoanPlans.map((loan) => {
            return loan.status.id !== 500 ? (
              <Card className="w-full sm:w-1/2">
                <Card.Body className={`font-medium ${loan.status.id === 600 ? "bg-green-400 font-bold" : ""}`}>
                  {loan.status.id === 600 ? (
                    <></>
                  ) : (
                    <>
                      <Card.Title>
                        {`ብድር ከነ ወለዱ: ${(loan.loanBalance + loan.amountPaid)?.toLocaleString()} ብር`}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {`ከጠቅላላ ቀሪ: ${loan.loanBalance?.toLocaleString()} ብር`}
                      </Card.Subtitle>
                    </>
                  )}

                  <Card.Subtitle className="mb-2 text-muted">
                    {`የተወሰደው የብድር መጠን: ${loan.originalLoan?.toLocaleString()} ብር`}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    {`የተከፈለ: ${loan.amountPaid?.toLocaleString()} ብር`}
                  </Card.Subtitle>
                  <Card.Text>የብድር ሁኔታ ፡ {loan?.status?.id === 600 ? "ተከፍሎ ያለቀ " : " ገና "}</Card.Text>
                </Card.Body>

                {loan.status.id === 600 ? (
                  <></>
                ) : (
                  <>
                    <Card.Footer>
                      <Link
                        to={`/loan300detail/${loan.id}`}
                        className="font-medium text-orange-400 underline hover:underline text-xl"
                      >
                        የብድር ሙሉ መረጃ
                      </Link>
                    </Card.Footer>
                  </>
                )}
              </Card>
            ) : null;
          })
        ) : (
          <Alert variant="danger"> የተገኘ የብድር አካውንት የለም (የካሁን በፊት ብድር የለብዎትም) </Alert>
        )}
      </div>
      <Card className="mt-2 bg-green-700">
        <Card.Header>{activeLoanPlans.length > 0 && <div> የብድር እቅድ ተይዟል </div>}</Card.Header>
        <Card.Body>
          {Object.keys(lpsLoanPlan).length > 0 ? (
            <Link
              to={`/loanlpsstatus/${lpsLoanPlan?.customer?.id}/${activeLoanPlans[0]?.id}`}
              className="font-medium text-orange-400 underline hover:underline text-xl"
            >
              ብድሩ ያለበት ሁኔታ ለማየት
            </Link>
          ) : (
            <Alert> ብድር ለማስጀመር የአሚጎስ ባለሙያዎች ይነጋገሩ </Alert>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default Savings;
