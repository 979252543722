import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../50.png";

import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { custLogout } from "./redux/authSlice";
import { LuLogOut } from "react-icons/lu";

function AuthenticatedNav() {
  const [t, i18n] = useTranslation("global");
  const { data } = useSelector((store) => store.customer);
  const { isAuthenticated } = useSelector((store) => store.customer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="flex h-screen ">
      <div className={`flex-1 w-full h-full `}>
        <nav className="bg-gray-100 text-black py-3  px-4 sm:px-20 flex justify-between items-center   border-b-2">
          <div className="flex flex-row items-center gap-2">
            {isAuthenticated ? (
              <Link to="/savings">
                <img
                  src={Logo}
                  width="30vh"
                  height="150vh"
                  alt="logo"
                  rounded
                />
              </Link>
            ) : (
              <Link to="/">
                <img
                  src={Logo}
                  width="30vh"
                  height="150vh"
                  alt="logo"
                  rounded
                />
              </Link>
            )}
          </div>

          {isAuthenticated ? (
            <ul className="flex justify-center text-xl gap-3">
              <Link to="/loanrequest"> ብድር-መጠየቅያ </Link>
              {/* <Link to="/loans"> ብድሮች </Link> */}
              <NavDropdown
                title={<span>{data?.username}</span>}
                id="navbarScrollingDropdown"
                style={{ fontWeight: "bold" }}
              >
                <NavDropdown.Item>
                  <Link to="/profile"> የግል ማህደር </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link to="/changepass"> የይለፍ ቃል ቀይር </Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  style={{ display: "flex", justifyContent: "space-between" }}
                  onClick={() => {
                    dispatch(custLogout());
                    navigate("/login");
                  }}
                >
                  {t("auth.logout")} <LuLogOut />
                </NavDropdown.Item>
              </NavDropdown>
            </ul>
          ) : (
            <ul className="flex justify-center text-xl gap-3">
              <Link to="/faqs"> FAQS </Link>
              <Link to="/services"> Services </Link>
              <Link to="/aboutus"> ስለኛ </Link>
              <Link to="/login"> ግባ </Link>
            </ul>
          )}
        </nav>

        <div className="p-3">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AuthenticatedNav;
