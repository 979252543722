import React, { useEffect, useState } from "react";
import NumberInputTw from "./ReusableFormiks/NumberInputTw";
import DateInputTw from "./ReusableFormiks/DateInputTw";
import axios from "axios";
import { Button, Card, ListGroup, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";

function LoanRequestForm() {
  const { data } = useSelector((store) => store.customer);
  const { serverIP } = useSelector((store) => store.allsettings);

  const [txtComment, settxtComment] = useState({});

  const [myApplicationsSoFar, setMyApplicationsSoFar] = useState([]);
  const [expectedDisbursementDate, setexpectedDisbursementDate] = useState();
  const [multiplier, setmultiplier] = useState();
  const [requestedAmount, setrequestedAmount] = useState();

  const [loanSaving, setLoanSaving] = useState([]);
  const [voluntary, setVoluntary] = useState([]);
  const [funeral, setFuneral] = useState([]);
  const [compulsary, setCompulsary] = useState([]);
  const [timeDeposit, setTimeDeposit] = useState([]);

  const [loanSavingSum, setLoanSavingSum] = useState(0);
  const [voluntarySum, setVoluntarySum] = useState(0);
  const [funeralSum, setFuneralSum] = useState(0);
  const [compulsarySum, setCompulsarySum] = useState(0);
  const [timeDepositSum, setTimeDepositSum] = useState(0);

  const [shareAccountTotal, setShareAccountTotal] = useState(0);

  useEffect(() => {
    getMyApplicationsSoFar();
    getAllSavings();
  }, []);

  const getAllSavings = async () => {
    try {
      const res_as = await axios.get(
        `https://back.amigoslps.com/fineract/allsaving?entityAccountNo=${data.clients[0]}`
      );

      setLoanSaving(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("LOAN")));

      setVoluntary(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("VOLUN")));

      setFuneral(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("FUN")));

      setCompulsary(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("COMP")));

      setTimeDeposit(res_as.data.savingsAccounts?.filter((x) => x.productName.toString().includes("TIME")));

      const sum_shares = res_as.data.shareAccounts?.reduce((accum, obj) => {
        return accum + obj.totalApprovedShares;
      }, 0);

      setShareAccountTotal(sum_shares);
    } catch (error) {}
  };

  useEffect(() => {
    const sum_lnsaving = loanSaving?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setLoanSavingSum(sum_lnsaving);

    const sum_voluntary = voluntary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setVoluntarySum(sum_voluntary);

    const sum_funeral = funeral?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setFuneralSum(sum_funeral);

    const sum_compulsary = compulsary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setCompulsarySum(sum_compulsary);

    const sum_timed = timeDeposit?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setTimeDepositSum(sum_timed);
  }, [loanSaving, voluntary, funeral, compulsary, timeDeposit]);

  const getMyApplicationsSoFar = async () => {
    const myapps = await axios.get(`https://back.amigoslps.com/ssloanrequest/myapplications/${data?.username}`);
    setMyApplicationsSoFar(myapps.data);
  };

  const sendRequest = () => {
    axios
      .post(`https://back.amigoslps.com/ssloanrequest/ssloanrequest`, {
        phonenumber: data?.username,
        entityAccountNo: data?.clients[0],
        entityExternalId: data?.clients[0],
        expectedDisbursementDate: expectedDisbursementDate,
        multiplier: multiplier,
        requestedAmount: requestedAmount,
        amount_timedeposit: timeDepositSum,
        amount_compulsary: compulsarySum,
        amount_voluntary: voluntarySum,
        amount_funeral: funeralSum,
        amount_loansaving: loanSavingSum,
        amount_share: shareAccountTotal,
      })
      .then((res) => {
        console.log(res.data);
        getMyApplicationsSoFar();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteRequest = (id) => {
    axios
      .delete(`https://back.amigoslps.com/ssloanrequest/ssloanrequest/${id}`)
      .then((res) => {
        getMyApplicationsSoFar();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postComment = (loanId) => {
    axios
      .post(`${serverIP}/sscomments/ssloancomments`, {
        comment: txtComment,
        loan: loanId,
      })
      .then((res) => {
        getMyApplicationsSoFar();
      })
      .catch((err) => {});
  };

  return (
    <div>
      {myApplicationsSoFar.length === 0 && (
        <Card>
          <Card.Header> ብድር መጠየቅያ ፎርም </Card.Header>
          <Card.Body>
            <NumberInputTw onChange={(e) => setrequestedAmount(e.target.value)} placeholder="የተጠየቀው መጠን" />
            <NumberInputTw onChange={(e) => setmultiplier(e.target.value)} placeholder="የቀጠባ እጥፍ" />
            <DateInputTw onChange={(e) => setexpectedDisbursementDate(e.target.value)} placeholder="የብድር መውሰጃ ቀን" />
          </Card.Body>
          <Card.Footer className="flex justify-end">
            <Button onClick={sendRequest}> ጥያቄ ላክ </Button>
          </Card.Footer>
        </Card>
      )}

      <div className="mt-2">
        {myApplicationsSoFar.length > 0 &&
          myApplicationsSoFar.map((app) => {
            return (
              <Card className="mt-2">
                <Card.Header className="flex justify-between">
                  <div> {app.requestedAmount?.toLocaleString()} </div>
                  <div className="flex items-center">
                    <FaTrashAlt
                      onClick={() => {
                        deleteRequest(app.id);
                      }}
                      color="red"
                    />
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="mb-2">
                    <div> የብድር ሁኔታ </div>
                    <div>
                      {app.loanPlanId === null ? (
                        <ProgressBar animated variant="danger" label="እየታየ ነው" now="30" />
                      ) : (
                        <ProgressBar animated variant="primary" label="መረጃዎትን የመከታተል ፈቃድ አግኝተዋል" now="60" />
                      )}
                    </div>
                  </div>

                  <ListGroup as="ul" style={{ padding: 0 }}>
                    <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
                      <div> የተጠየቀው ቀን </div>
                      {app.expectedDisbursementDate}
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
                      <div> የተጠየቀው የብር መጠን </div>
                      {app.requestedAmount?.toLocaleString()}
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between items-center px-3 py-1">
                      <div> የቁጠባ ስንት እጥፍ </div>
                      {app.multiplier}
                    </ListGroup.Item>
                  </ListGroup>

                  <div class="p-2 mt-2 bg-gray-100 max-w-md mx-auto rounded-lg max-h-90 overflow-y-auto">
                    {app.ssloancomment.length > 0 &&
                      app.ssloancomment.map((comment) => {
                        return comment.commentedBy !== null ? (
                          <div class="flex items-end space-x-2 mb-2">
                            <div class="flex w-8 h-8 rounded-full items-center justify-center bg-gray-400">
                              {Array.from(comment.commentedBy.first_name)[0]}.
                              {Array.from(comment.commentedBy.first_name)[0]}
                            </div>
                            <div class="bg-white py-1 px-3 rounded-lg shadow-md max-w-xs">
                              <p class="text-gray-700">{comment.comment} </p>
                              <span class="text-xs text-gray-400 block text-right mt-1">10:30 AM</span>
                            </div>
                          </div>
                        ) : (
                          <div class="flex items-end space-x-2 mb-2 justify-end">
                            <div class="bg-green-200  py-1 px-3 rounded-lg shadow-md max-w-xs">
                              <p class="text-gray-700">{comment.comment}</p>
                              <span class="text-xs text-gray-500 block text-right mt-1">10:32 AM</span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Card.Body>
                <Card.Footer className="flex items-center space-x-2 mt-4 bg-white p-2 rounded-full shadow-md">
                  <input
                    type="text"
                    placeholder="ጥያቄውን ይጻፉ..."
                    onChange={(e) => settxtComment(e.target.value)}
                    class="flex-1 px-4 py-2 text-sm border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
                  />
                  <button
                    onClick={(e) => {
                      postComment(app.id);
                    }}
                    class="px-3 py-2 bg-green-500 text-white rounded-full hover:bg-green-600"
                  >
                    ጠይቅ
                  </button>
                </Card.Footer>
              </Card>
            );
          })}
      </div>
    </div>
  );
}

export default LoanRequestForm;
