import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Profile() {
  const { data } = useSelector((store) => store.customer);
  const [user, setUser] = useState([]);
  const [clientreport, setClientReport] = useState([]);

  const [clientType, setClientType] = useState("");

  useEffect(() => {
    setClientType(clientreport?.length > 0 ? clientreport[clientreport?.length - 1] : "");
  }, [clientreport]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = () => {
    axios
      .get(
        `https://back.amigoslps.com/fineract/userprofile?entityAccountNo=${data?.clients[0]}&base64EncodedAuthenticationKey=${data?.base64EncodedAuthenticationKey}`
      )
      .then((res) => {
        console.log(res.data);
        setUser(res?.data);
        axios
          .get(`https://back.amigoslps.com/fineract/clientreport?entityExternalId=${res?.data?.externalId}`)
          .then((res) => {
            setClientReport(res?.data?.data[0]?.row);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div
        className="row"
        style={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ListGroup as="ul">
          <div class="flex items-center justify-center">
            <div class="relative w-32 h-32 mb-5">
              <img
                src="https://vias.placeholder.com/150"
                class="w-full h-full rounded-full border-4 border-white shadow-lg object-cover"
              />
            </div>
          </div>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto"> externalId </div>
            <div>{user?.externalId}</div>
          </ListGroup.Item>

          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto"> accountNo </div>
            <div>{user?.accountNo}</div>
          </ListGroup.Item>

          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto"> የቢሮ ስም </div>
            <div>{user?.officeName}</div>
          </ListGroup.Item>

          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto"> ሙሉ ስም </div>
            <div>{user?.displayName}</div>
          </ListGroup.Item>

          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto"> ስልክ ቁጥር </div>
            <div>{user?.mobileNo}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto"> የልደት ቀን (YYYY-MM-DD) </div>
            <div>{user?.dateOfBirth?.join("-")}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto"> ጾታ </div>
            <div>{user?.gender?.name}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start bg-orange-300">
            <div className="ms-2 me-auto"> የደንበኛ አይነት </div>
            <div>{clientType}</div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
}

export default Profile;
