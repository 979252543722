import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import KeyValue from "./ReusableFormiks/KeyValue";

import ReactECharts from "echarts-for-react";

import {
  datesLeftForNextMonthPay,
  currentMonthPaymentMadeOrNot,
} from "./Common/modules/RepaymentDates";

function Loan300detail() {

  const [paidMonhthsCount, setpaidMonthsCount] = useState(0);
  const [unpaidMonhthsCount, setunpaidMonthsCount] = useState(0);

  const option = {
    title: {
      text: "የብድር መራጃ",
      subtext: "የተመለሰ እና ቀሪ መረጃ በፓይ ቻርት",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "left",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: "50%",
        data: [
          { value: paidMonhthsCount, name: "የተከፈለ" },
          { value: unpaidMonhthsCount, name: "ያልተከፈለ" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const { loanId } = useParams();
  const [loanDetail, setLoanDetail] = useState({});
  const [repayPeriods, setrepayPeriods] = useState([]);

 

  useEffect(() => {
    getLoan300Detail();
  }, []);

  useEffect(() => {
    setpaidMonthsCount(repayPeriods.filter((x) => x.complete).length);
    setunpaidMonthsCount(repayPeriods.filter((x) => !x.complete).length);
  }, [repayPeriods]);

  const getLoan300Detail = async () => {
    try {
      const the_detail = await axios.get(
        `https://back.amigoslps.com/fineract/clientloanplan?loanPlanId=${loanId}`
      );
      setLoanDetail(the_detail.data);
      setrepayPeriods(the_detail.data?.repaymentSchedule?.periods);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div>
      <div>
        <KeyValue
          thekey="የተበዳሪ ሙሉ ስም"
          value={loanDetail.clientName}
          isodd="False"
        />
        <KeyValue
          thekey="የተበዳሪ አካውንት ቁጥር "
          value={loanDetail.clientAccountNo}
          isodd="False"
        />
      </div>

      <div className="flex flex-col sm:flex-row w-full gap-2">
        <div className="w-full sm:w-1/2">
          <Card>
            <Card.Header>የብድር አጠቃላይ መረጃ</Card.Header>
            <Card.Body>
              <KeyValue thekey="የብድር ቁጥር" value={loanDetail.id} isodd="True" />
              <KeyValue
                thekey="ብድር የተወሰደበት ቀን"
                value={loanDetail?.timeline?.actualDisbursementDate.join("-")}
                isodd="False"
              />
              {/* loanDetail.timeline.expectedMaturityDate.join("-") */}
              <KeyValue
                thekey="ብድር ተመልሶ የሚያልቅበት ቀን"
                value={repayPeriods[repayPeriods.length - 1]?.dueDate.join("-")}
                isodd="True"
              />
              <KeyValue
                thekey="የተከፈለው የወር ብዛት"
                value={`${paidMonhthsCount} ወራት`}
                isodd="False"
              />
              <KeyValue
                thekey="ያልተከፈለው የወር ብዛት"
                value={`${unpaidMonhthsCount - 2} ወራት`}
                isodd="True"
              />
              <KeyValue
                thekey="ጠቅላላ የሚከፈል የወር ብዛት"
                value={`${repayPeriods.length - 2} ወራት`}
                isodd="False"
              />
              <KeyValue
                thekey="የተከፈለ ብዛት በመቶኛ "
                value={`${(paidMonhthsCount / repayPeriods.length) * 100} %`}
                isodd="True"
              />
              <KeyValue
                thekey="ብድር ከነወለዱ "
                value={`${loanDetail?.summary?.totalExpectedRepayment?.toLocaleString()} ብር`}
                isodd="False"
              />

              <KeyValue
                thekey="ያልተከፈለ ከነወለዱ "
                value={`${loanDetail?.summary?.totalOutstanding?.toLocaleString()} ብር`}
                isodd="True"
              />
            </Card.Body>
          </Card>
        </div>
        <div className="w-full sm:w-1/2">
          <Card>
            <Card.Header>የተመለሰ እና ቀሪ መረጃ በፓይ ቻርት</Card.Header>
            <Card.Body>
              <ReactECharts option={option} />
              <KeyValue
                thekey="ያለፈው ወር ክፍያ"
                value={
                  currentMonthPaymentMadeOrNot(repayPeriods)
                    ? " ተከፍሏል "
                    : "አልተከፈለም"
                }
                isodd="False"
              />
              <KeyValue
                thekey="ለቀጣይ ክፍያ የቀሩት ቀናት"
                value={datesLeftForNextMonthPay(repayPeriods)}
                isodd="True"
              />
              <KeyValue
                thekey="ወርሃዊ ክፍያ መጠን"
                value={loanDetail?.fixedEmiAmount?.toLocaleString()}
                isodd="False"
              />
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Loan300detail;
