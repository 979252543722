import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import global_en from "./Common/translations/en/global.json";
import global_am from "./Common/translations/am/global.json";
import "react-toastify/dist/ReactToastify.css";

import AuthenticatedNav from "./Common/AuthenticatedNav";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "am",
  compatibilityJSON: "v3",
  resources: {
    en: {
      global: global_en,
    },
    am: {
      global: global_am,
    },
  },
});

function App() {
  const { isAuthenticated } = useSelector((store) => store.customer);

  return (
    <React.Fragment>
      <AuthenticatedNav />
      {/* {isAuthenticated ? <AuthenticatedNav /> : <Navigate to="/" />} */}
    </React.Fragment>
  );
}

export default App;
