import React, { useState } from "react";
import { Form, Button, Container, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ChangePass() {
  const [t, i18n] = useTranslation("global");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle password change here
    console.log(currentPassword, newPassword, confirmNewPassword);
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-3"></div>
        <div className="col-sm-6">
          <Container className="mt-5">
            <Alert> {t("auth.changepassword")} </Alert>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="currentPassword">
                <Form.Label>{t("common.currentPassword")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("common.enterEurrentPassword")}
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="newPassword">
                <Form.Label>{t("common.newPassword")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("common.enterNewPassword")}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="confirmNewPassword">
                <Form.Label>{t("common.confirmNewPassword")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("common.confirmNewPassword")}
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </Form.Group>

              <div style={{ display:"flex", justifyContent:"flex-end" }}>
              <Button variant="primary" type="submit">
              {t("auth.changepassword")} 
              </Button>
              </div>
            </Form>
          </Container>
        </div>
        <div className="col-sm-3"></div>
      </div>
    </div>
  );
}

export default ChangePass;
